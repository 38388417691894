import React from 'react'
import propTypes from 'prop-types'
import RelatedPostCard from '../related-post-card'
import { Typography, Box } from '@mui/material'
import ProvenResponsiveContainer from '../../../../shared/proven-responsive-container'

function RelatedPosts({ title, blogposts }) {
  const columnsNum = blogposts && blogposts.length > 2 ? 3 : blogposts.length
  return (
    <Box width={1} pt={{ xs: 10, md: 12 }} pb={13} sx={{ backgroundColor: 'gray.beige' }}>
      <ProvenResponsiveContainer>
        {title && (
          <Typography variant="h3" textAlign="center" mb={{ xs: 4, md: 8 }} component="h4">
            {title}
          </Typography>
        )}
        <Box display="flex" justifyContent="center">
          <Box
            display="grid"
            sx={{ gridTemplateColumns: ['1fr', '1fr', `repeat(${columnsNum}, 1fr)`] }}
            columnGap={{ xs: 7, md: 4 }}
          >
            {blogposts?.map(post => {
              const postData = post?.data ? post : post?.post?.value

              if (!postData && !postData?.data) {
                return null
              }
              return (
                <RelatedPostCard
                  key={post?.id}
                  title={postData?.data?.title}
                  summary={postData?.data?.summary}
                  seoTitle={postData?.data?.seoTitle}
                  url={postData?.data?.url}
                  featuredImage={postData?.data?.featuredImage}
                  thumbnails={postData?.data?.thumbnails}
                  categories={postData?.data?.categories}
                />
              )
            })}
          </Box>
        </Box>
      </ProvenResponsiveContainer>
    </Box>
  )
}

RelatedPosts.propTypes = {
  title: propTypes.string,
  blogposts: propTypes.array.isRequired
}

export default RelatedPosts
