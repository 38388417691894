import React from 'react'
import propTypes from 'prop-types'
import { Box } from '@mui/material'
import { onDesktop } from '../../../../../styles/theme-proven/responsive'

const LoginTitleWrap = ({ children, ...props }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        gap: 1,
        mb: 0,
        pl: 2,
        pr: 2,
        pt: props?.isMagicLinkForm && props?.isFromQuiz ? 10 : 0,
        [onDesktop]: {
          mb: 1,
          pl: 0,
          pr: 0,
          pt: props?.isMagicLinkForm && props?.isFromQuiz ? 10 : 0
        }
      }}
      {...props}
    >
      {children}
    </Box>
  )
}

LoginTitleWrap.propTypes = {
  children: propTypes.array
}

export default LoginTitleWrap
