import React from 'react'
import PropTypes from 'prop-types'
import { Typography, CardMedia, CardContent, Card } from '@mui/material'
import { useHistory } from 'react-router-dom'
import useMediaQuery from '@mui/material/useMediaQuery'

export const RELATED_POST_ORIENTATION_VERTICAL = 'vertical'
export const RELATED_POST_ORIENTATION_HORIZONTAL = 'horizontal'

const RelatedPostCard = ({
  url,
  seoTitle,
  featuredImage,
  thumbnails,
  categories,
  title,
  summary,
  orientation,
  sx
}) => {
  const categoryName = (categories || [])
    .map(category => category?.category?.value?.data?.name)
    .join(', ')
  const history = useHistory()
  const isMdUp = useMediaQuery(theme => theme.breakpoints.up('md'))

  const goToPost = e => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
    history.push(url)
  }

  const isHorizontal = orientation === RELATED_POST_ORIENTATION_HORIZONTAL
  const summaryCut = summary && `${summary.slice(0, isMdUp ? 200 : 110)}...`

  return (
    <Card
      data-testid="related-post-card-testId"
      variant="noBackground"
      sx={{
        display: isHorizontal ? 'flex' : 'block',
        gap: isHorizontal ? '32px' : 'none',
        width: { xs: 1 },
        p: 0,
        mx: isHorizontal ? 0 : 'auto',
        cursor: 'pointer',
        maxWidth: isHorizontal ? 'unset' : 400,
        ...sx
      }}
      onClick={goToPost}
    >
      <CardMedia
        component="img"
        height={isHorizontal ? 'none' : '200'}
        image={thumbnails || featuredImage}
        alt={seoTitle}
        loading="lazy"
        sx={{
          width: isHorizontal ? '50%' : '100%',
          height: '244px'
        }}
      />
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          pt: isHorizontal ? 0 : 2,
          pb: 1,
          px: 0,
          width: isHorizontal ? '50%' : '100%',
          height: isHorizontal ? '244px' : 'none',
          maxHeight: isHorizontal ? '244px' : 'none'
        }}
      >
        <Typography variant="footnote" color="gray.middleGray">
          {categoryName}
        </Typography>
        <Typography variant="title2">{title}</Typography>
        <Typography variant="body2">{summaryCut}</Typography>
      </CardContent>
    </Card>
  )
}

RelatedPostCard.defaultProps = {
  orientation: RELATED_POST_ORIENTATION_VERTICAL
}

RelatedPostCard.propTypes = {
  url: PropTypes.string.isRequired,
  seoTitle: PropTypes.string.isRequired,
  featuredImage: PropTypes.string,
  thumbnails: PropTypes.string,
  categories: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  summary: PropTypes.string,
  orientation: PropTypes.oneOf(['vertical', 'horizontal']).isRequired
}

export default RelatedPostCard
