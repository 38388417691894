import React from 'react'
import propTypes from 'prop-types'

import './styles.scss'

function SocialShare({ url, title }) {
  const encodedTitle = encodeURI(title)
  const encodedUrl = encodeURI(url)
  const twitterUrl = `https://twitter.com/share?text=${encodedTitle}&url=${encodedUrl}`
  const facebookUrl = `https://facebook.com/sharer/sharer.php?u=${encodedUrl}`
  const linkedinUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodedUrl}&title=${encodedTitle}`

  const onClick = (event, sharerUrl) => {
    event.preventDefault()
    window.open(sharerUrl, title, 'height=650,width=550,top=0,left=100,resizable')
  }

  return (
    <nav styleName="socialShare">
      <a
        target="_blank"
        onClick={e => onClick(e, twitterUrl)}
        href="#"
        styleName="shareLink twitter"
      >
        Twitter
      </a>
      <a
        target="_blank"
        onClick={e => onClick(e, facebookUrl)}
        href="#"
        styleName="shareLink facebook"
      >
        Facebook
      </a>
      <a
        target="_blank"
        onClick={e => onClick(e, linkedinUrl)}
        href="#"
        styleName="shareLink linkedin"
      >
        LinkedIn
      </a>
    </nav>
  )
}

SocialShare.propTypes = {
  url: propTypes.string.isRequired,
  title: propTypes.string.isRequired
}

export default SocialShare
