import React from 'react'
import propTypes from 'prop-types'
import { Typography, Box, Divider } from '@mui/material'
import { onDesktop } from '../../../../../styles/theme-proven/responsive'
import ProvenResponsiveContainer from '../../../../shared/proven-responsive-container'
import useMediaQuery from '@mui/material/useMediaQuery'

const addProtocolIfNeed = url => {
  return url
}

function ScientificCitations({ title = 'Scientific Citations', scientificNotations }) {
  const isMdUp = useMediaQuery(theme => theme.breakpoints.up('md'))

  if (scientificNotations?.length === 0) {
    return null
  }

  return (
    <Box width={1} pt={{ xs: 10, md: 12 }} pb={13} sx={{ backgroundColor: 'gray.white' }}>
      <ProvenResponsiveContainer>
        {isMdUp && (
          <Divider variant="fullWidth" sx={{ mb: 3, opacity: 0.2, color: 'gray.blueGray' }} />
        )}
        {title && (
          <Typography variant="title2" color="gray.elysian" mb={3} component="p">
            {title}
          </Typography>
        )}
        {!isMdUp && (
          <Divider variant="fullWidth" sx={{ mb: 3, opacity: 0.2, color: 'gray.blueGray' }} />
        )}
        <Box
          sx={{
            display: 'grid',
            rowGap: 2,
            [onDesktop]: {
              gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
              gridAutoFlow: 'columns',
              columnGap: 3
            }
          }}
        >
          {scientificNotations?.map((sn, index) => {
            return (
              <Box key={`scientific-notation-key-${index}`}>
                {sn.link ? (
                  <Typography
                    component="a"
                    href={addProtocolIfNeed(sn.link)}
                    variant="footnote"
                    target="_blank"
                    color="gray.blueGray"
                    rel="noopener noreferrer"
                    sx={{
                      wordWrap: 'break-word',
                      cursor: 'pointer'
                    }}
                  >
                    {`${index + 1}. ${sn.scientificNotation}`}
                  </Typography>
                ) : (
                  <Typography
                    variant="footnote"
                    color="gray.blueGray"
                    sx={{
                      wordWrap: 'break-word'
                    }}
                  >
                    {`${index + 1}. ${sn.scientificNotation}`}
                  </Typography>
                )}
              </Box>
            )
          })}
        </Box>
      </ProvenResponsiveContainer>
    </Box>
  )
}

ScientificCitations.propTypes = {
  title: propTypes.string,
  scientificNotations: propTypes.array
}

export default ScientificCitations
