import React from 'react'
import { Box } from '@mui/material'
import './styles.scss'
import { matchPath, useLocation } from 'react-router-dom'

const LoginPromoOffer = () => {
  const location = useLocation()
  const isWelcomeBackEmailPage = () => {
    return matchPath(location.pathname, {
      path: '/welcome-back-email',
      exact: true,
      strict: false
    })
  }

  return (
    <React.Fragment>
      {isWelcomeBackEmailPage() && (
        <Box sx={{ display: { xs: 'block', md: 'none' } }}>
          <div styleName="promo-offer">
            <div styleName="promo-wrapper">
              <div styleName="tag-line">Extra&nbsp;20%&nbsp;off</div>
              <div styleName="sub-text">
                <span styleName="code">CODE:&nbsp;FIREWORKS&nbsp;|&nbsp;</span>
                <span styleName="deadline">Ends&nbsp;7/5/21</span>
              </div>
            </div>
          </div>
        </Box>
      )}
    </React.Fragment>
  )
}

export default LoginPromoOffer
