import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import propTypes from 'prop-types'
import { Card, CardContent, Typography, Link } from '@mui/material'
import { onDesktop } from '../../../../styles/theme-proven/responsive'
import usePathBasedOnIntent from '../../../../hooks/usePathBasedOnIntent'

const DATA_CY_PREFIX = 'login-footer'

const LoginFooter = ({ closeLoginForm }) => {
  const { getQuizPathBasedOnIntent } = usePathBasedOnIntent()
  const handleClickQuiz = () => {
    if (closeLoginForm) closeLoginForm()
  }

  return (
    <Card
      sx={{
        width: 1,
        backgroundColor: 'gray.beige',
        maxWidth: theme => theme.spacing(46),
        [onDesktop]: {
          maxWidth: theme => theme.spacing(62)
        }
      }}
    >
      <CardContent sx={{ p: 4, [onDesktop]: { p: 3 } }}>
        <Typography
          variant="title2"
          align="center"
          data-cy={`${DATA_CY_PREFIX}-title`}
          component="p"
        >
          New to PROVEN?
        </Typography>

        <Typography variant="body2" align="center" sx={{ mt: 1, [onDesktop]: { mt: 2 } }}>
          Start your{' '}
          <Link onClick={handleClickQuiz} to={getQuizPathBasedOnIntent()} component={RouterLink}>
            3-minute Skincare Quiz
          </Link>
        </Typography>
      </CardContent>
    </Card>
  )
}

LoginFooter.propTypes = {
  closeLoginForm: propTypes.func
}

export default LoginFooter
