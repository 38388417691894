import React from 'react'
import Button from '@mui/material/Button'
import ProvenIcon from 'components/shared/proven-icon'
import { Auth } from 'aws-amplify'
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth'
import { trackEvent } from 'utils/analytics'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setPathBeforeLogin } from '../../../../actions/auth.actions'
const DATA_CY_PREFIX = 'social-buttons'

function SocialButtonsLogin() {
  const history = useHistory()
  const dispatch = useDispatch()

  return (
    <div>
      <Button
        variant="outlined"
        fullWidth
        startIcon={<ProvenIcon type="social" name="sign-in-social-facebook" />}
        sx={{ mb: 2, color: 'primary.blue' }}
        onClick={_e => {
          trackEvent('login_cta', {
            CTA: 'Facebook',
            type: 'facebook'
          })
          dispatch(setPathBeforeLogin(history?.location?.pathname))
          newrelic.addPageAction('loginType', { options: 'Facebook' })
          Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Facebook })
        }}
        data-cy={`${DATA_CY_PREFIX}-facebook-button`}
      >
        Sign in with Facebook
      </Button>
      <Button
        variant="outlined"
        fullWidth
        startIcon={<ProvenIcon type="social" name="sign-in-social-google" />}
        onClick={_e => {
          trackEvent('login_cta', {
            CTA: 'Google',
            type: 'google-oauth2'
          })
          dispatch(setPathBeforeLogin(history?.location?.pathname))
          newrelic.addPageAction('loginType', { options: 'Google' })
          Auth.federatedSignIn({
            provider: CognitoHostedUIIdentityProvider.Google,
            customState: 'urlBeforeLogin:' + history?.location?.pathname
          })
        }}
        data-cy={`${DATA_CY_PREFIX}-google-button`}
      >
        Sign in with Google
      </Button>
    </div>
  )
}

export default SocialButtonsLogin
