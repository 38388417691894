import React from 'react'
import propTypes from 'prop-types'
import { Box } from '@mui/material'
import { onDesktop } from '../../../../../styles/theme-proven/responsive'

const DATA_CY_PREFIX = 'login-slider-content'

const LoginContent = ({ children, isMagicLinkForm, ...props }) => {
  return (
    <Box
      {...props}
      sx={{
        maxWidth: '100vw',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        py: 0,
        [onDesktop]: {
          maxWidth: theme => theme.spacing(51)
        }
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          textAlign: 'center',
          pt: !isMagicLinkForm ? 2 : 0
        }}
        data-cy={DATA_CY_PREFIX}
      >
        {children}
      </Box>
    </Box>
  )
}

LoginContent.propTypes = {
  children: propTypes.array,
  isMagicLinkForm: propTypes.bool
}

export default LoginContent
