// eslint-disable-next-line
import React, { useEffect } from "react";
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

function PebblePost() {
  const location = useLocation()
  const accountInfo = useSelector(state => state.account.info)
  const checkout = useSelector(state => state.checkout)

  useEffect(() => {
    try {
      const anonymousId = localStorage.getItem('ajs_anonymous_id')
      if (
        document &&
        document.body &&
        window &&
        anonymousId &&
        location &&
        !window.location.hostname.includes('localhost') &&
        !window.location.hostname.includes('staging')
      ) {
        const isThankYouPage = location.pathname.includes('/account/thank-you')
        const SESSION_ID = anonymousId
        const CUSTOMER_USER_ID = accountInfo && accountInfo._id ? accountInfo._id : ''
        const CUSTOMER_EMAIL = accountInfo && accountInfo.email ? accountInfo.email : ''
        const ECOMMERCE_ORDER_ID =
          isThankYouPage && checkout && checkout.mockOrderId ? checkout.mockOrderId : ''
        const ECOMMERCE_ORDER_VALUE =
          isThankYouPage && checkout && checkout.orderDetails && checkout.orderDetails.total
            ? (checkout.orderDetails.total / 100).toFixed(2)
            : ''
        const ECOMMERCE_OFFER_ID =
          isThankYouPage && checkout && checkout.coupon ? checkout.coupon : ''
        if (isThankYouPage && !ECOMMERCE_ORDER_ID && !ECOMMERCE_ORDER_VALUE) {
          return
        }

        const pebblePostScript = document.createElement('script')

        pebblePostScript.innerHTML =
          `window._pp = window._pp || [];` +
          `_pp.brid = "2693";` +
          `_pp.brcid = "${CUSTOMER_USER_ID}";` +
          `_pp.sessionId = ${SESSION_ID};` +
          `_pp.email = "${CUSTOMER_EMAIL}";` +
          `_pp.orderValue = "${ECOMMERCE_ORDER_VALUE}";` +
          `_pp.orderId = "${ECOMMERCE_ORDER_ID}";` +
          `_pp.offerCode = "${ECOMMERCE_OFFER_ID}";` +
          `(function() {` +
          `var ppjs = document.createElement('script');` +
          `ppjs.type = 'text/javascript';` +
          `ppjs.async = true;` +
          `ppjs.src = ('https:' === document.location.protocol ? 'https:' : 'http:') + '//cdn.pbbl.co/r/' + _pp.brid + '.js';` +
          `var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(ppjs, s);` +
          `})();`
        document.body.appendChild(pebblePostScript)

        return () => {
          document.body.removeChild(pebblePostScript)
        }
      }
    } catch (e) {
      console.error(e)
    }
  }, [location])

  return null
}

export default PebblePost
