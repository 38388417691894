import React from 'react'
import propTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Button, Box } from '@mui/material'

const DATA_CY_PREFIX = 'magic-link-login-section'

const EmailPasswordLogin = ({ email, onToggleEmailPassword }) => {
  const { prepopulatedEmail } = useSelector(state => state.auth)

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: 1,
        gap: 2
      }}
    >
      <Button
        data-cy={`${DATA_CY_PREFIX}-email-me-link-button`}
        variant="contained"
        color="secondary"
        fullWidth
        onClick={e => onToggleEmailPassword(e, prepopulatedEmail || email)}
      >
        Sign In with Password
      </Button>
    </Box>
  )
}

EmailPasswordLogin.propTypes = {
  onToggleEmailPassword: propTypes.func.isRequired
}

export default EmailPasswordLogin
