import propTypes from 'prop-types'
import { append, tail, without } from 'ramda'
import React, { useEffect } from 'react'
import ProvenMultipleQuizSelector from '../../../shared/proven-multiple-quiz-selector'
import { Box } from '@mui/material'
import ConcernMultipleQuizSelector from '../../../shared/proven-multiple-quiz-selector/concern'

// this style is used when the multple selector is inside a sidebar
export const SELECT_MULTIPLE_CHECK_BOX_WITH_SIDEBAR_STYLE = 'check-box-with-sidebar'
export const SELECT_MULTIPLE_LIGHT_GREEN_STYLE = 'light-green'

function SelectMultiple({
  items,
  name,
  answer,
  _id,
  setAnswer,
  questionnaireId,
  maxAnswers,
  multipleSelectorStyle
}) {
  useEffect(() => {
    if (!answer) {
      setAnswer({ [_id]: [] }, questionnaireId)
    }
  }, [])

  const selectAnswer = ({ value, checked }) => {
    const newValue = checked ? append(+value, answer) : without([+value], answer)

    const limit = maxAnswers && newValue.length > maxAnswers
    setAnswer(
      {
        [_id]: limit ? tail(newValue) : newValue
      },
      questionnaireId
    )
  }

  const buildConcernStyle = () => {
    if (multipleSelectorStyle === SELECT_MULTIPLE_CHECK_BOX_WITH_SIDEBAR_STYLE) {
      return {
        display: 'flex',
        justifyContext: 'space-between',
        gap: { xs: 2, md: 3 }
      }
    }
    return {
      display: 'grid',
      gridTemplateColumns: { xs: 'repeat(2, 1fr)', md: 'repeat(3, 1fr)' },
      gridAutoRows: '1fr',
      gap: { xs: 2, md: 3 }
    }
  }

  const concernStyleBox = buildConcernStyle()

  if (name === 'concern') {
    return (
      <Box sx={concernStyleBox}>
        {items.map(({ text, value }) => (
          <ConcernMultipleQuizSelector
            label={text}
            value={value}
            name={name}
            onChange={selectAnswer}
            key={text}
            selected={answer && answer.includes(value)}
            multipleSelectorStyle={multipleSelectorStyle}
          />
        ))}
      </Box>
    )
  }

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
      {items.map(({ text, value }) => (
        <ProvenMultipleQuizSelector
          label={text}
          value={value}
          name={name}
          onChange={selectAnswer}
          key={text}
          selected={answer && answer.includes(value)}
          multipleSelectorStyle={multipleSelectorStyle}
        />
      ))}
    </Box>
  )
}

SelectMultiple.propTypes = {
  answer: propTypes.array,
  name: propTypes.string,
  _id: propTypes.string,
  items: propTypes.array,
  maxAnswers: propTypes.number,
  setAnswer: propTypes.func,
  questionnaireId: propTypes.string
}

export default SelectMultiple
