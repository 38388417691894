import React, { Component } from 'react'
import { connect } from 'react-redux'
import { pathOr } from 'ramda'
import propTypes from 'prop-types'
import { clearAuth } from '../../../../actions/auth.actions'
import { withRouter, matchPath } from 'react-router-dom'
import { toggleLoginSlide, closeLoginSlide } from '../../../../actions/checkout.actions'
import SocialButtonsLogin from '../social-buttons'
import EmailPasswordLogin from '../email-password-btn'
import MagicLinkForm from './magic-link-form'
import { withWidth } from '../../../../hooks/useWidth'
import { Typography, Divider } from '@mui/material'
import LoginContent from '../shared/login-content'
import LoginTitleWrap from '../shared/login-title-wrap'
import LoginPromoOffer from '../shared/promo-offer'

const DATA_CY_PREFIX = 'login-password'

class LoginMagicLinkForm extends Component {
  constructor(props) {
    super(props)
    this.props.clearAuth()

    this.state = {
      email: this.props.email
    }
  }

  componentDidMount() {
    if (this.inPasswordResetPage()) {
      this.props.closeLoginSlide()
    }
  }

  inPasswordResetPage = () => {
    return matchPath(this.props.history.location.pathname, {
      path: '/account/change-password',
      exact: true,
      strict: false
    })
  }

  inGiftRedeemFormPage = () => {
    return matchPath(this.props.history.location.pathname, {
      path: '/gift-redeem',
      exact: true,
      strict: false
    })
  }

  getTitle = () => {
    const { giftCard, title } = this.props
    if (this.inGiftRedeemFormPage() || giftCard) {
      return 'Sign in to redeem your Gift Card'
    }
    return title ? title : 'Login to PROVEN'
  }

  updateEmail = email => {
    this.setState({ email })
  }

  render() {
    const title = this.getTitle()
    const defaultSubTitle =
      'Please enter your email. We will send you an email with a link to view your PROVEN profile.'
    const { displayOnlySocialLogin, onToggleEmailPassword, subTitle } = this.props

    return (
      <LoginContent>
        <LoginPromoOffer />

        {displayOnlySocialLogin ? (
          <Typography variant="h3" data-cy={`${DATA_CY_PREFIX}-social-buttons-title`}>
            Login With
          </Typography>
        ) : (
          <>
            <LoginTitleWrap>
              <Typography
                style={{ color: '#303C42' }}
                variant="h3"
                data-cy={`${DATA_CY_PREFIX}-title`}
              >
                {title}
              </Typography>
              <Typography variant="body2">{subTitle || defaultSubTitle}</Typography>
            </LoginTitleWrap>

            <MagicLinkForm
              email={this.state.email}
              updateEmail={this.updateEmail}
              onToggleEmailPassword={onToggleEmailPassword}
            />

            <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
              <Divider>OR</Divider>
            </div>
          </>
        )}

        <SocialButtonsLogin />

        <EmailPasswordLogin
          email={this.state.email}
          onToggleEmailPassword={onToggleEmailPassword}
        />
      </LoginContent>
    )
  }
}

LoginMagicLinkForm.propTypes = {
  nextCheckoutStep: propTypes.number,
  giftCard: propTypes.string,
  clearAuth: propTypes.func.isRequired,
  title: propTypes.string,
  closeLoginSlide: propTypes.func,
  toggleLoginSlide: propTypes.func,
  onToggleEmailPassword: propTypes.func,
  subTitle: propTypes.string
}

LoginMagicLinkForm.defaultProps = {
  nextCheckoutStep: 0
}

const mapStateToProps = state => {
  const giftCard = pathOr(null, ['checkout', 'giftCard'], state)
  return {
    giftCard
  }
}

const mapDispatchToProps = dispatch => ({
  clearAuth: () => dispatch(clearAuth()),
  closeLoginSlide: toggle => dispatch(closeLoginSlide(toggle)),
  toggleLoginSlide: toggle => dispatch(toggleLoginSlide(toggle))
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withWidth(LoginMagicLinkForm))
)
