import PropTypes from 'prop-types'
import React from 'react'
import Box from '@mui/material/Box'

const buildGridTemplateColumns = (isMultiColumn, gridTemplateColumns) => {
  if (gridTemplateColumns) {
    return gridTemplateColumns
  } else if (isMultiColumn) {
    return ['1fr', '1fr', '1fr', '1fr 1fr']
  } else {
    return ['1fr', '1fr', '4fr 2fr']
  }
}

const QuestionGrid = ({
  children,
  isMultiColumn = false,
  gridTemplateColumns,
  showDropshadow = false
}) => {
  return (
    <Box
      display="grid"
      sx={{
        gridTemplateColumns: buildGridTemplateColumns(isMultiColumn, gridTemplateColumns),
        filter: showDropshadow
          ? 'drop-shadow(0px 8.14815px 6.51852px rgba(48, 60, 66, 0.0196296))'
          : 'unset'
      }}
      rowGap={{ xs: 1, lg: 2 }}
      columnGap={3}
    >
      {children}
    </Box>
  )
}

QuestionGrid.propTypes = {
  children: PropTypes.node.isRequired,
  isMultiColumn: PropTypes.bool
}

export default QuestionGrid
