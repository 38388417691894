import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { clearAuth, clearPasswordlessAuth } from '../../../../../actions/auth.actions'
import { checkValidUser } from '../../../../../actions/auth0.actions'
import { trackEvent } from '../../../../../utils/analytics'
import { USER_NOT_FOUND } from '../../../../../constants/actionTypes'
import { Link as RouterLink } from 'react-router-dom'
import LoginContent from '../../shared/login-content'
import LoginTitleWrap from '../../shared/login-title-wrap'
import { Button, Typography, Link, TextField, Stack, Box } from '@mui/material'
import { closeLoginSlide, toggleLoginSlide } from '../../../../../actions/checkout.actions'
import { updateAccountInfo } from '../../../../../actions/account.actions'
import ProvenIcon from '../../../../shared/proven-icon'
import { onDesktop } from '../../../../../styles/theme-proven/responsive'
import withAuth from 'hoc/with-auth'
import { formatTimer } from '../../../../../utils/helpers'
import { withSupportEmail } from '../../../../../hooks/useSupportEmail'

const DATA_CY_PREFIX = 'magic-link-form'

function MagicLinkForm({
  email: emailProp,
  startPasswordlessAuth,
  onToggleEmailPassword,
  onResetPassword,
  magicLinkTitle = 'We found your skin profile!',
  isMagicLinkSent,
  handleResetPasswordClose,
  updateEmail,
  supportEmail,
  isFromQuiz
}) {
  const dispatch = useDispatch()
  const {
    prepopulatedEmail,
    error,
    loading,
    message = '',
    passwordless
  } = useSelector(state => state.auth)
  const passwordlessError = passwordless?.error
  const passwordlessErrorMessage = passwordless?.message || ''
  const { isWelcomeBackPage } = useSelector(state => state.app)

  const [email, setEmail] = useState(emailProp)
  const [sent, setSent] = useState(false)
  const [resetTimer, setResetTimer] = useState(null)
  const timerIntervalRef = useRef(null)
  const sentEmailRef = useRef(null)

  useEffect(() => {
    dispatch(clearAuth())
    setResetTimer(0)
    const intervalId = setInterval(() => {
      setResetTimer(prev => prev + 1)
    }, 1000)
    timerIntervalRef.current = intervalId
  }, [])

  useEffect(() => {
    if (resetTimer >= 60) {
      setResetTimer(null)
      sentEmailRef.current = true
      if (timerIntervalRef.current) {
        clearInterval(timerIntervalRef.current)
      }
    }
  }, [resetTimer])

  const handleMagicLinkRequest = async event => {
    event.preventDefault()
    event.stopPropagation()
    let userExists
    let passwordResetRequired = false
    try {
      const { status } = await checkValidUser(email, true)
      userExists = true
      if (status === 'RESET_REQUIRED') {
        passwordResetRequired = true
      }
    } catch (_err) {
      userExists = false
    }
    if (userExists && !passwordResetRequired) {
      trackEvent('login_cta', {
        CTA: 'Get Email Link',
        type: 'magiclink',
        email
      })
    }

    dispatch(clearPasswordlessAuth())

    // false means user needs a pw reset
    const worked = await startPasswordlessAuth?.(email)
    if (!worked) return onResetPassword()
    setSent(true)
    onToggleEmailPassword(event, prepopulatedEmail)
    dispatch(updateAccountInfo({ isMagicLinkSent: true }))
    dispatch(updateAccountInfo({ magicLinkEmail: email }))
  }

  const onToggleSignInWithPassword = e => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
    dispatch(closeLoginSlide())
    dispatch(
      toggleLoginSlide(
        {
          view: 2,
          navBtnTxt: 'Back',
          closeFunc: () => dispatch(closeLoginSlide)
        },
        email
      )
    )
  }

  const subTitleMagicLink = `We sent a unique login link to ${email}. <strong>Please check your email</strong> and click on the link to login.`
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flex: 1,
        '& form': {
          width: '100%'
        }
      }}
    >
      <LoginContent component="form" onSubmit={handleMagicLinkRequest} isMagicLinkForm={true}>
        {isMagicLinkSent ? (
          <Button
            variant="text"
            size="medium"
            onClick={handleResetPasswordClose}
            sx={{
              alignSelf: 'flex-start',
              px: 1,
              py: 0,
              pt: 1.5,
              height: 'auto',
              mb: 2,
              position: 'absolute',
              top: '20px',
              left: '25px',
              display: 'flex',
              [onDesktop]: { height: 'auto' }
            }}
            startIcon={<ProvenIcon type="system" name="cross-close" />}
            data-cy={`${DATA_CY_PREFIX}-close-back-button`}
          >
            Close
          </Button>
        ) : null}
        {(sent || isMagicLinkSent) && !isWelcomeBackPage && (
          <LoginTitleWrap isMagicLinkForm={true} isFromQuiz={isFromQuiz}>
            <Typography variant="h3" data-cy={`${DATA_CY_PREFIX}-title`}>
              {magicLinkTitle}
              <br />
            </Typography>
            <Typography
              variant="body2"
              data-cy={`${DATA_CY_PREFIX}-sub-title`}
              dangerouslySetInnerHTML={{ __html: subTitleMagicLink }}
            />
            {sentEmailRef.current && resetTimer !== null ? (
              <Stack flexDirection="row" alignItems="center" gap={1}>
                <CheckCircleIcon color="success" sx={{ fontSize: '16px' }} />
                <Typography variant="footnote" color="success.main">
                  A new link has been sent to you email
                </Typography>
              </Stack>
            ) : (
              <></>
            )}
            {resetTimer !== null && (
              <Box mt={2}>
                <Stack flexDirection="row" alignItems="center" gap={0.5}>
                  <Typography variant="body2" color="gray.blueGray">
                    {`Didn't receive the link? Resend in `}
                  </Typography>
                  <Typography variant="body2" color="primary">
                    {formatTimer(60 - resetTimer)}
                  </Typography>
                </Stack>
              </Box>
            )}
          </LoginTitleWrap>
        )}

        {((!sent && !isMagicLinkSent) || isWelcomeBackPage) && (
          <TextField
            onChange={e => {
              updateEmail?.(e.target.value)
              setEmail(e.target.value)
            }}
            id="email"
            type="email"
            autoComplete="email"
            placeholder="Enter your email"
            inputProps={{
              onFocus: () => dispatch(clearAuth())
            }}
            value={email}
            autoFocus
          />
        )}

        {(error === USER_NOT_FOUND || passwordlessError === USER_NOT_FOUND) && (
          <Typography
            variant="body2"
            color="error"
            data-cy={`${DATA_CY_PREFIX}-email-not-found-error-text`}
          >
            We cannot find your email. Please enter a different email or{' '}
            <Link
              component={RouterLink}
              to="/quiz/concern"
              color="error"
              data-cy={`${DATA_CY_PREFIX}-email-not-found-error-take-quiz-link`}
              onClick={() => dispatch(closeLoginSlide)}
            >
              take skin quiz.
            </Link>
          </Typography>
        )}

        {error !== USER_NOT_FOUND && message && (
          <Typography variant="body2" color="error">
            {message}
          </Typography>
        )}

        {['unauthorized'].includes(passwordlessError) && passwordlessErrorMessage && (
          <Typography variant="body2" color="error">
            {passwordlessErrorMessage}
          </Typography>
        )}

        {(sent || isMagicLinkSent) && !isWelcomeBackPage ? (
          resetTimer === null && (
            <>
              <Link
                component="p"
                typography="hyperlink"
                onClick={async () => {
                  await startPasswordlessAuth?.(email, {
                    saveQuizAnswers: true
                  })
                  setResetTimer(0)
                  const intervalId = setInterval(() => {
                    setResetTimer(prev => prev + 1)
                  }, 1000)
                  timerIntervalRef.current = intervalId
                }}
                data-cy={`${DATA_CY_PREFIX}-did-not-receive-link-error-text`}
              >
                Resend email
              </Link>
              <Link
                component="p"
                typography="hyperlink"
                sx={{
                  pt: '12px !important'
                }}
                onClick={onToggleSignInWithPassword}
                data-cy={`${DATA_CY_PREFIX}-sign-in-with-pasword-text`}
              >
                Sign in with password
              </Link>
            </>
          )
        ) : (
          <Button
            variant="contained"
            color="secondary"
            disabled={!email || loading}
            fullWidth
            data-cy={`${DATA_CY_PREFIX}-send-magic-link-button`}
            data-testid={`${DATA_CY_PREFIX}-send-magic-link-button`}
            type="submit"
          >
            Email me a link
          </Button>
        )}
      </LoginContent>
      {(sent || isMagicLinkSent) && !isWelcomeBackPage && (
        <Box
          sx={{
            textAlign: 'center',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            pb: 6.5
          }}
        >
          <Typography variant="footnote" color="gray.blueGray">
            {`Need help?`}
          </Typography>
          <Typography variant="footnote" color="gray.blueGray">
            {`Email us: `}
            <Link href={`mailto:${supportEmail}`} component="a">
              {supportEmail}
            </Link>
          </Typography>
        </Box>
      )}
    </Box>
  )
}

export default withSupportEmail(withAuth(MagicLinkForm))
