import { Component } from 'react'
import { pathOr } from 'ramda'
import { verifyPasswordAuth } from '../../../actions/auth0.actions'
import { getUserDetails } from '../../../actions/account.actions'
import propTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { getQueryStringValue } from '../../../utils/helpers'
import {
  failPasswordlessAuth as failPasswordlessAuthAction,
  onAuthSetupAndRedirect
} from 'actions/auth.actions'

class AmpushAuthUser extends Component {
  async componentDidMount() {
    const { failPasswordlessAuth, verifyPasswordAuth, history } = this.props
    const email = decodeURIComponent(
      Buffer.from(getQueryStringValue('u'), 'base64').toString('utf8')
    )
    const password = decodeURIComponent(
      Buffer.from(getQueryStringValue('p'), 'base64').toString('utf8')
    )
    // const email = decodeURIComponent(getQueryStringValue("u"));
    // const password = decodeURIComponent(getQueryStringValue("p"));

    try {
      await verifyPasswordAuth(email, password)
      // TODO Ampush is not being used
      // below is a new post-auth method
      // if this component should be used again - make sure to test the auth flow for it
      onAuthSetupAndRedirect()
      //identifyUser();
      //await getUserDetails();
      //history.push({pathname:`/account/congratulations${queryString}`, state: {isNewbie: true}});
      //history.push(`/account/congratulations${queryString}`);
    } catch (e) {
      history.push({ pathname: '/', state: { loginOpen: true } })
      failPasswordlessAuth(email, false)
    }
  }

  render() {
    return null
  }
}

const mapStateToProps = state => {
  const queryString = pathOr('', ['auth', 'queryString'], state)
  return {
    queryString: queryString
  }
}

const mapDispatchToProps = dispatch => ({
  failPasswordlessAuth: username => dispatch(failPasswordlessAuthAction(username)),
  verifyPasswordAuth: (username, password) => dispatch(verifyPasswordAuth(username, password)),
  getUserDetails: callback => dispatch(getUserDetails(callback)),
  onAuthSetupAndRedirect: () => dispatch(onAuthSetupAndRedirect())
})

AmpushAuthUser.propTypes = {
  history: propTypes.object,
  verifyPasswordlessAuth: propTypes.func,
  queryString: propTypes.string
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AmpushAuthUser))
